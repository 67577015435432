import { useSelector } from 'react-redux';
import ProductOptionsConfigureElement from './ProductOptionsConfigureElement';
import TranslationService from "../../../services/translation.service";

const ProductOptionsConfigure = () => {

    const products = useSelector(state => state.productpage.products);

    if (products) {

        let formfields = [];
        products.forEach((p, i) => {
            formfields.push(
                <ProductOptionsConfigureElement key={i} subnum={i} product={p} />
            )
        });

        return (
            <>
                <div className="included-products">
                    <h3>{TranslationService.translate('Dieses Paket beinhaltet:')}</h3>
                    <form className="config-selection">
                        {formfields}
                    </form>
                </div>
            </>
        );

    }

}

export default ProductOptionsConfigure;
