import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ApiService from "../../../services/api.service";
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';
import TranslationService from "../../../services/translation.service";

const ProductCouponCode = ({ setCouponActivePrice }) => {

    const dispatch = useDispatch();
    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);
    const cart = useSelector(state => state.cart);
    const coupon = useSelector(state => state.productpage.coupon);

    const predefinedcode = useSelector(state => state.global.predefinedcode);
    const [predefinedCodeCheck, setPredefinedCodeCheck] = useState(false);
    const [predefinedCodeChecked, setPredefinedCodeChecked] = useState(false);


    function changeInput(e) {
        e.target.value === '' ? removeCode() : dispatch(CartStore.setCartCouponCode(e.target.value))
    }

    function checkCode() {
        if (cart.couponcode) {
            const API_URL = global.config.express.url + '/checkcoupon';
            const API_DATA = {
                'shop-url': global.config.apidata.shopurl,
                'cart': cart
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (response.coupon.status === 'success') {
                    dispatch(ProductPageStore.setCoupon({
                        'state': response.coupon.status,
                        'message': TranslationService.translate(response.coupon.message),
                        'price': response.coupon.finalprice,
                        'value': response.coupon.couponvalue,
                        'shippingpackagingprice': response.coupon.shippingpackagingprice,
                        'language': global.config.language
                    }));
                    setCouponActivePrice(response.coupon.finalprice)
                }
                else {
                    dispatch(ProductPageStore.setCoupon({
                        'state': response.coupon.status,
                        'message': TranslationService.translate(response.coupon.message)
                    }));
                    setCouponActivePrice(null)
                }
            });
        }
    };

    function removeCode() {
        dispatch(CartStore.setCartCouponCode(null));
        dispatch(ProductPageStore.setCoupon());
        setCouponActivePrice(null);
    };

    useEffect(() => {
        if (!predefinedCodeCheck && predefinedcode) {
            cart.couponcode = predefinedcode;
            setPredefinedCodeCheck(true);
        }
    }, [predefinedCodeCheck, predefinedcode, cart])


    if (contingent !== 0) {
        if (contingent === null || minqty < contingent) {

            if (predefinedCodeCheck && !predefinedCodeChecked) {
                checkCode();
                setPredefinedCodeChecked(true);
            }

            if (typeof (coupon) === 'undefined' || coupon.state === 'error') {
                let couponvalue = cart.couponcode === null ? '' : cart.couponcode;

                return (
                    <>
                        <div className="coupon">
                            <label>{TranslationService.translate('Aktionscode zur Hand?')}</label>
                            <input onChange={(e) => changeInput(e)} className="code" name="code" value={couponvalue} />
                            <button onClick={() => checkCode()} type="button" className="send-code">{TranslationService.translate('Einlösen')}</button>
                            {typeof (coupon) !== 'undefined' && coupon.state === 'error' ? <p className="error">{coupon.message}</p> : null}
                        </div>
                    </>
                )
            }

            if (coupon.state === 'success') {
                return (
                    <>
                        <div className="coupon">
                            <label>{TranslationService.translate('Aktionscode zur Hand?')}</label>
                            <input readOnly className="code" name="code" value={cart.couponcode} />
                            <button onClick={() => removeCode()} type="button" title={TranslationService.translate('Aktionscode entfernen')} className="remove-code">X</button>
                            <p className="success">{coupon.message}</p>
                        </div>
                    </>
                )
            }

        }
    }

}


export default ProductCouponCode;
