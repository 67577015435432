import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import OrderTable from '../orderviewpage/elements/OrderTable';
import ProductImageBox from '../orderviewpage/elements/ProductImageBox';
import Summary from '../orderviewpage/elements/Summary';
import CheckoutPayments from './CheckoutPayments';
import ApiService from "../../services/api.service";
import * as GlobalStore from '../../store/global/actions';
import TranslationService from "../../services/translation.service";

const CheckoutOverview = ({ activeStep, type, cart }) => {

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activePayment, setActivePayment] = useState([]);
    const checkoutcart = useSelector(state => state.cart);
    const coupon = useSelector(state => state.productpage.coupon);
    const activeProductData = useSelector(state => state.productpage.productdata);

    const finalshoppingcart = useSelector(state => state.shoppingcart.finalshoppingcart);

    function confirmOrder(e) {

        if (global.config.preview) {
            alert('Diese Funktion ist in der Vorschau nicht verfügbar.');
            return false;
        }

        e.target.setAttribute('disabled', true);
        e.target.classList.add('wait');
        e.target.firstChild.data = TranslationService.translate('Bestellung wird verarbeitet');

        if (useshoppingcart && finalshoppingcart) {
            finalshoppingcart['payment-tupel'] = activePayment;
            finalshoppingcart['billing-address'] = cart['billing-address'];
            finalshoppingcart['shipping-address'] = cart['shipping-address'];
        }

        checkoutcart['payment-tupel'] = activePayment;
        checkoutcart['billing-address'] = cart['billing-address'];
        checkoutcart['shipping-address'] = cart['shipping-address'];

        const API_URL = global.config.express.url + '/cart/transfer';
        const API_DATA = {
            'shop-url': global.config.apidata.shopurl,
            'cart': useshoppingcart && finalshoppingcart ? finalshoppingcart : checkoutcart,
            'framepage': window.location.origin + window.location.pathname + '?mode=react',
            'shoppingcart': useshoppingcart ? true : false,
            'backendshop': global.config.backendshop,
            'exchange': global.config.exchange,
            'language': global.config.language
        }

        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (response.status === 'success') {
                if (response.url) {
                    window.location.replace(response.url);
                }
            }
            else {
                dispatch(GlobalStore.setApiError(response.message));
                navigate('?path=bestellung-fehlgeschlagen', { replace: true });
            }
        });
    }

    if (activeStep === 3) {

        let shippingheadline;
        let shippingtext;

        if (type === 'email') {
            shippingheadline = TranslationService.translate('Versand');
            shippingtext = TranslationService.translate('Lieferung per E-Mail an:') + '<br />' + cart['shipping-address'].email;
        }
        else {
            shippingheadline = TranslationService.translate('Versand an');
            shippingtext =
                cart['shipping-address']['first-name'] + ' ' + cart['shipping-address']['last-name']
                + '<br />'
                + cart['shipping-address'].street
                + '<br />'
                + cart['shipping-address']['postal-code'] + ' ' + cart['shipping-address'].city;
        }

        let show_ssl = true
        let paybuttontext = TranslationService.translate('Jetzt kaufen');

        if (typeof (activeProductData) !== 'undefined') {
            if (activeProductData.price === 0 || (coupon && coupon.price === 0 && coupon.shippingpackagingprice === 0)) {
                paybuttontext = TranslationService.translate('Jetzt bestellen')
                show_ssl = false
            }
        }

        return (
            <div className="container nopadding overview">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                        <h1>{TranslationService.translate('Bestellübersicht')}</h1>
                    </div>
                </div>
                <div className="row flex-nocenter">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 sized">
                        <div className="pbox big">
                            <h3>{TranslationService.translate('Rechnungsadresse')}</h3>
                            <p>
                                {cart['billing-address']['first-name']} {cart['billing-address']['last-name']}<br />
                                {cart['billing-address'].street}<br />
                                {cart['billing-address']['postal-code']} {cart['billing-address'].city}<br />
                                {cart['billing-address'].email}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 sized">
                        <div className="pbox big">
                            <h3>{shippingheadline}</h3>
                            <p dangerouslySetInnerHTML={{ __html: shippingtext }}></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="pbox big">
                            <h3>{TranslationService.translate('Bezahlung')}</h3>
                            <CheckoutPayments activePayment={activePayment} setActivePayment={setActivePayment} cart={cart} />
                        </div>
                    </div>
                </div>
                <div className="row distance-top">

                    {!useshoppingcart ? <div className="col-lg-4 hidden-md hidden-sm col-xs-12 sized"><ProductImageBox product={cart['order-product-bundle-groups'][0]} /></div> : ''}


                    <div className={(useshoppingcart ? 'col-lg-12' : 'col-lg-8') + " col-md-12 col-sm-12 col-xs-12"}>
                        <OrderTable products={cart['order-product-bundle-groups']} />
                        <div className="row mflex">
                            <div className={(useshoppingcart ? 'col-lg-4 col-lg-offset-8' : 'col-lg-5 col-lg-offset-7') + " col-md-4 col-md-offset-8 col-sm-4 col-sm-offset-8 col-xs-12 t-right mf1"}>
                                <Summary cart={cart} />
                            </div>
                        </div>
                        <div className="row lastpaystep">
                            <div className={(useshoppingcart ? 'col-lg-3 col-lg-offset-9' : 'col-lg-5 col-lg-offset-7') + " col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6 col-xs-12 t-right"}>
                                <p className="t-left">{TranslationService.translate('Mit Absenden der Bestellung bestätige ich, dass ich die')} <a href={window.location.pathname + '?path=agb'} target="_blank" rel="noreferrer">{TranslationService.translate('AGB')}</a>, {TranslationService.translate('Hinweise zum')} <a href={window.location.pathname + '?path=datenschutz'} target="_blank" rel="noreferrer">{TranslationService.translate('Datenschutz')}</a> {TranslationService.translate('und die')} <a href={window.location.pathname + '?path=widerrufsbelehrung'} target="_blank" rel="noreferrer">{TranslationService.translate('Widerrufsbelehrungen')}</a>{TranslationService.translate(' gelesen habe. Ich stimme diesen ausdrücklich zu.')}</p>
                                <button onClick={(e) => confirmOrder(e)} type="button" className="confirmorder">{paybuttontext}</button>
                                {show_ssl ? <p className="ssl"><span></span>{TranslationService.translate('Sichere Zahlung SSL')}</p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default CheckoutOverview;