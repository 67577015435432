import { Fragment } from 'react';
import TranslationService from "../../../services/translation.service";

const ProductShippingBox = ({ cart, shippingAddress, setShowShippingForm }) => {

    let type = cart['order-distribution-products'][0]['order-method-product'].type;
    let deliverytime = cart['order-distribution-products'][0]['order-method-product']['delivery-time'];

    let view = [];

    if (type === 'email') {
        view.push(
            <Fragment key='0'>
                <h3>{TranslationService.translate('Lieferung per E-Mail an:')}</h3>
                <p>{cart['billing-address'].email}</p>
            </Fragment>
        )
    }

    else {
        let name = shippingAddress['first-name'] + ' ' + shippingAddress['last-name'];
        let street = shippingAddress.street;
        let postal = shippingAddress['country-code'] + '-' + shippingAddress['postal-code'];
        let city = shippingAddress.city;

        view.push(
            <Fragment key='0'>
                <h3>{TranslationService.translate('Lieferung per Post an:')}</h3>
                <p className="shippingaddressdata">{name}<br />{street}<br />{postal} {city}</p>
                <button onClick={() => setShowShippingForm(true)} className="edit"></button>
            </Fragment>
        )
    }

    view.push(
        <p key="dt" className="green">{TranslationService.translate('Lieferzeit:')} {deliverytime}</p>
    )

    return (
        <>
            <div className="pbox">
                {view}
            </div>
        </>
    )

}

export default ProductShippingBox;