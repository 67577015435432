import { Component } from 'react';
import { Link } from 'react-router-dom';
import TranslationService from "../services/translation.service";

class PageNotFound extends Component {
    render() {
        return (
            <div className="container t-center">
                <h1>404 - {TranslationService.translate('Seite nicht gefunden')}</h1>
                <p>{TranslationService.translate('Die aufgerufene Seite existiert leider nicht bzw. nicht mehr.')}<br /><br /></p>
                <p><Link className='button' to="?path=start">{TranslationService.translate('Zurück zur Startseite')}</Link></p>
            </div>
        );
    }
}

export default PageNotFound;
