export const resetShoppingCart = data => ({
  type: 'RESET_SHOPPINGCART',
  data
});

export const setShoppingCart = cartproductsdata => ({
  type: 'SET_SHOPPINGCART',
  cartproductsdata
});

export const removeFromShoppingCart = index => ({
  type: 'REMOVE_SHOPPINGCARTPRODUCT',
  index
});

export const setShoppingCartCouponCode = shoppingcartcouponcode => ({
  type: 'SET_SHOPPINGCARTCOUPONCODE',
  shoppingcartcouponcode
});

export const setFinalShoppingCart = finalshoppingcart => ({
  type: 'SET_FINALSHOPPINGCART',
  finalshoppingcart
});

