import { useSelector } from 'react-redux';
import TranslationService from "../../services/translation.service";

const ProductSteps = () => {

    const product = useSelector(state => state.productpage.data);

    if (typeof (product) !== 'undefined' && product.length !== 0) {
        return (
            <>
                <div className="container nopadding">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="manual">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <span className="present">{TranslationService.translate("So funktioniert's")}</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                    <span className="num">1</span>
                                    <span className="numtext">{TranslationService.translate("Produkt auswählen!")}</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                    <span className="num">2</span>
                                    <span className="numtext">{TranslationService.translate("Sicher online bezahlen!")}</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                    <span className="num">3</span>
                                    <span className="numtext">{TranslationService.translate("E-Mails checken!")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductSteps;
