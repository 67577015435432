import { useSelector } from 'react-redux';
import ProductOptionsAppointmentElement from './ProductOptionsAppointmentElement';
import TranslationService from "../../../services/translation.service";

const ProductOptionsAppointment = () => {

    const products = useSelector(state => state.productpage.products);
    const product = useSelector(state => state.productpage);

    if (products) {
        let bundleContingent = product.appointment.bundleContingent;
        let formfields = [];
        products.forEach((p, i) => {
            formfields.push(
                <ProductOptionsAppointmentElement key={i} subnum={i} product={p} bundleContingent={bundleContingent} />
            )
        });

        return (
            <>
                <div className="included-products">
                    <h3>{TranslationService.translate('Dieser Termin beinhaltet:')}</h3>
                    <form className="config-selection">
                        {formfields}
                    </form>
                </div>
            </>
        );
    }

}

export default ProductOptionsAppointment;
