import { useSelector } from 'react-redux';
import TranslationService from "../../../services/translation.service";

const ProductDeliveryTime = () => {

    const distribution = useSelector(state => state.productpage.distributionproducts);
    const shippingid = useSelector(state => state.productpage.shipping.id);
    const contingent = useSelector(state => state.productpage.productdata.contingent);

    let deliveryHtml;
    distribution.forEach((packaging) => {
        let shippingitems = packaging['shipping-packaging'];
        shippingitems['shipping-method-products'].forEach((method) => {
            if (method.id === shippingid && contingent !== 0) {
                deliveryHtml = <span className="deliverytime">{TranslationService.translate('Lieferzeit')}: <span className="dt">{method['delivery-time']}</span></span>
            }
        });
    });

    return (
        <>
            {deliveryHtml}
        </>
    );

}

export default ProductDeliveryTime;
