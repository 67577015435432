import translationdata from './translations.json';

class TranslationService {
    translate(data) {
        let gwlanguage = global.config.gwlanguage ? global.config.gwlanguage : 'de';
        if (gwlanguage === 'de') {
            return data;
        }
        else {
            if (translationdata[data] && translationdata[data][gwlanguage]) {
                return translationdata[data][gwlanguage];
            }
            else {
                return data;
            }
        }
    }
}

const exportData = new TranslationService();
export default exportData;
