import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ShoppingCartStore from '../store/shoppingcart/actions';
import TranslationService from "../services/translation.service";

const OrderPendingPage = () => {

    const dispatch = useDispatch();
    dispatch(ShoppingCartStore.resetShoppingCart());

    return (
        <div className="container nopadding overview">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>{TranslationService.translate('Bestellung in Bearbeitung')}</h1>
                    <p><strong>{TranslationService.translate('Wir warten auf den Zahlungseingang!')}</strong></p>
                    <p>{TranslationService.translate('Sobald wir die Zahlung erhalten haben, senden wir eine E-Mail mit allen weiteren Informationen.')}</p>
                    <p><br /><Link className='button' to="?path=start">{TranslationService.translate('Zurück zur Startseite')}</Link></p>
                </div>
            </div>
        </div>
    );
}

export default OrderPendingPage;
