import { useState, useEffect } from 'react';

import ProductImageBox from './elements/ProductImageBox';
import OrderTable from './elements/OrderTable';
import ProductShippingBox from './elements/ProductShippingBox';
import ProductPaymentBox from './elements/ProductPaymentBox';
import Summary from './elements/Summary';
import ConfirmButton from './elements/ConfirmButton';
import ChangeShippingForm from './elements/ChangeShippingForm';
import { useSelector } from 'react-redux';
import TranslationService from "../../services/translation.service";

const OrderOverviewContent = ({ cart, scriptcontent, identifier }) => {


    const useshoppingcart = useSelector(state => state.global.useshoppingcart);

    const [products, setProducts] = useState();
    const [shippingAddress, setShippingAddress] = useState({});
    const [showShippingForm, setShowShippingForm] = useState(false);

    useEffect(() => {
        if (typeof (cart) !== 'undefined') {
            setProducts(cart['order-product-bundle-groups']);
            if (cart['shipping-address'] !== null) {
                setShippingAddress({
                    'first-name': cart['shipping-address']['first-name'],
                    'last-name': cart['shipping-address']['last-name'],
                    'street': cart['shipping-address'].street,
                    'country-code': cart['shipping-address']['country-code'],
                    'postal-code': cart['shipping-address']['postal-code'],
                    'city': cart['shipping-address'].city,
                    'email': cart['shipping-address'].email
                });
            }
        }
    }, [cart]);

    if (products) {
        let shippingbox;
        let paymentbox;
        let amazonscript;
        if (cart['payment-tupel']['payment-provider'] === 'amazon') {
            shippingbox = <div className="pbox" id="addressBookWidgetDiv"></div>;
            paymentbox = <div className="pbox" id="walletWidgetDiv"></div>;
            amazonscript = document.createElement('script');
            amazonscript.innerHTML = scriptcontent;
            setTimeout(function () {
                document.getElementById('walletWidgetDiv').appendChild(amazonscript);
            }, 1000);
        }
        else {
            shippingbox = <ProductShippingBox cart={cart} shippingAddress={shippingAddress} setShowShippingForm={setShowShippingForm} />;
            paymentbox = <ProductPaymentBox cart={cart} />;
        }
        if (cart['shipping-address'] !== null) {
            cart['shipping-address'] = shippingAddress;
        }
        return (
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>{TranslationService.translate('Bestellübersicht')}</h1>
                </div>

                {!useshoppingcart ? <div className="col-lg-4 hidden-md hidden-sm col-xs-12 sized"><ProductImageBox product={products[0]} /></div> : ''}

                <div className={(useshoppingcart ? 'col-lg-12' : 'col-lg-8') + " col-md-12 col-sm-12 col-xs-12"}>
                    <OrderTable products={products} />
                    <div className="row mflex">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 sized mf2">
                            {shippingbox}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 sized mf3">
                            {paymentbox}
                        </div>
                        <div className={(useshoppingcart ? 'col-lg-3 col-lg-offset-1' : 'col-lg-4') + " col-md-4 col-sm-4 col-xs-12 t-right mf1"}>
                            <Summary cart={cart} />
                        </div>
                    </div>
                    <div className="row lastpaystep">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 sized"></div>
                        <div className={(useshoppingcart ? 'col-lg-3 col-lg-offset-1' : 'col-lg-4') + " col-md-4 col-sm-4 col-xs-12 t-right"}>
                            <div className='lastpaystep'>
                                <p className="t-left">{TranslationService.translate('Mit Absenden der Bestellung bestätige ich, dass ich die')} <a href={window.location.pathname + '?path=agb'} target="_blank" rel="noreferrer">{TranslationService.translate('AGB')}</a>, {TranslationService.translate('Hinweise zum')} <a href={window.location.pathname + '?path=datenschutz'} target="_blank" rel="noreferrer">{TranslationService.translate('Datenschutz')}</a> {TranslationService.translate('und die')} <a href={window.location.pathname + '?path=widerrufsbelehrung'} target="_blank" rel="noreferrer">{TranslationService.translate('Widerrufsbelehrungen')}</a> {TranslationService.translate('gelesen habe. Ich stimme diesen ausdrücklich zu.')}</p>
                                <ConfirmButton cart={cart} identifier={identifier} />
                                <p className="ssl"><span></span>{TranslationService.translate('Sichere Zahlung SSL')}</p>
                            </div>
                        </div>
                    </div>
                    {showShippingForm === true ? <ChangeShippingForm data={shippingAddress} setData={setShippingAddress} setShowShippingForm={setShowShippingForm} /> : ''}
                </div>
            </>
        );
    }

}


export default OrderOverviewContent;
