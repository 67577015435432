import TranslationService from "../../../services/translation.service";

const OrderText = ({ cart }) => {

    const type = cart['order-distribution-products'][0]['order-method-product'].type;

    let text;

    if (type === 'email') {
        text =
            <>
                <h2>{TranslationService.translate('Informationen zur Bestellung')}</h2>
                <p>{TranslationService.translate('Wir haben soeben den Versand per E-Mail eingeleitet.')}</p>
                <p>{TranslationService.translate('Die Dokumente im Anhang dieser Mail können nun bequem ausgedruckt oder weitergeleitet werden.')}</p>
                <p>{TranslationService.translate('Wir wünschen viel Spaß beim Verschenken oder selbst einlösen.')}</p>
            </>
    }
    else {
        text =
            <>
                <h2>{TranslationService.translate('Informationen zur Bestellung')}</h2>
                <p>{TranslationService.translate('Wir verschicken wie gewünscht alles per Post und wünschen viel Spaß beim Verschenken oder selbst einlösen.')}</p>
            </>
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
            {text}
        </div>
    )

}

export default OrderText;