import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import ApiService from "../../../services/api.service";
import * as GlobalStore from '../../../store/global/actions';
import { useEffect, useState } from 'react';
import TranslationService from "../../../services/translation.service";

const PaymentButtons = ({ cart }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [payments, setPayments] = useState();

    useEffect(() => {
        const API_URL = global.config.express.url + '/getpayments';
        const API_DATA = {
            'shop-url': global.config.apidata.shopurl,
            'backendshop': global.config.backendshop,
            'exchange': global.config.exchange,
            'language': global.config.language
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            setPayments(response.paymentProviders);
        });
    }, []);


    let paymentButtons = [];
    let fastcheckoutproviders = [];
    let cartproviders = [];


    function setOrder(e, provider) {
        if (provider.active) {

            if (global.config.preview) {
                alert('Diese Funktion ist in der Vorschau nicht verfügbar.');
                return false;
            }

            e.target.setAttribute('disabled', true);

            let payment;
            payment = {
                'payment-tupel': {
                    'payment-provider': provider['payment-provider'],
                    'payment-method-category': provider['payment-method-category'],
                    'payment-method-value': provider['payment-method-value'],
                    'payment-kind': provider['payment-kind'],
                }
            };

            Object.assign(cart, payment);
            const API_URL = global.config.express.url + '/cart/transfer';
            const API_DATA = {
                'shop-url': global.config.apidata.shopurl,
                'cart': cart,
                'framepage': window.location.origin + window.location.pathname + '?mode=react',
                'shoppingcart': true,
                'language': global.config.language
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {

                if (response.status === 'success') {
                    if (response.url) {
                        window.location.replace(response.url);
                    }
                    if (response.script) {
                        const script = document.createElement('script');
                        script.innerHTML = response.script;
                        document.getElementsByTagName('head')[0].appendChild(script);
                        const overlay = document.createElement('div');
                        overlay.classList.add('gw_overlay');
                        overlay.innerHTML = '<div class="infobox"><h2>' + TranslationService.translate('Amazon Pay') + '</h2><p>' + TranslationService.translate('Mit Amazon Pay können Sie die in Ihrem Amazon-Account hinterlegten Zahlungs- und Versandinformationen nutzen, um schnell und sicher einzukaufen. Zudem genießen Sie den Käuferschutz, den Amazon mit der A-Z Garantie bietet.') + '</p></div>';
                        document.getElementsByTagName('body')[0].appendChild(overlay);
                        document.getElementsByClassName('infobox')[0].appendChild(document.getElementById('AmazonPayButton'));
                        document.getElementById('AmazonPayButton').style.display = 'block';
                        var isMobileSafari = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
                        setTimeout(function () {
                            var infobox = document.getElementsByClassName('infobox')[0];
                            var top = window.innerHeight / 2 - (infobox.offsetHeight / 2 + 50);
                            if (isMobileSafari) {
                                infobox.style.width = '100%';
                                infobox.style.left = '0';
                                infobox.style.top = top + 'px';
                            }
                            else {
                                var left = window.innerWidth / 2 - infobox.offsetWidth / 2;
                                infobox.style.left = left + 'px';
                                infobox.style.top = top + 'px';
                            }
                            infobox.style.opacity = 1;
                        }, 1000);
                    }
                }

                else {
                    dispatch(GlobalStore.setApiError(response.message));
                    navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                }

            });
        }
    }


    if (payments) {
        if (cart['cart-price']['gross-amount'].amount <= 0) {
            paymentButtons.push(
                <span key="or1" className="or"><span>{TranslationService.translate('direkt bestellen')}</span></span>
            )
            paymentButtons.push(
                <Link key="checkout" to="?path=kasse">
                    <button type="button" className="setorder checkout">{TranslationService.translate('Jetzt bestellen')}</button>
                </Link>
            )
        }

        else {
            payments.forEach((payment) => {
                payment['payment-call-tupel'].forEach((item, i) => {
                    if (item['payment-kind'] === 'Order') {
                        item.active = payment.active;
                        fastcheckoutproviders.push(item);
                    }
                    else {
                        cartproviders.push(item);
                    }
                });
            });

            if (fastcheckoutproviders.length) {
                paymentButtons.push(
                    <span key="or1" className="or"><span>{TranslationService.translate('direkt bezahlen mit')}</span></span>
                )
                fastcheckoutproviders.forEach((provider, key) => {
                    let type = provider['payment-provider'];
                    let active = provider.active ? '' : ' inactive';
                    let paymenticon;
                    if (provider['payment-provider-icon'] && provider['payment-provider-icon']['relative-path']) {
                        paymenticon = <img src={global.config.apidata.url + '/' + provider['payment-provider-icon']['relative-path']} alt={provider['payment-provider-icon']['alt']} />
                    }
                    else {
                        paymenticon = provider['payment-name'];
                    }
                    paymentButtons.push(
                        <button
                            type="button"
                            onClick={(e) => setOrder(e, provider)}
                            className={'setorder ' + type + active}
                            key={key}
                        >
                            {paymenticon}
                        </button>
                    );
                });
            }


            if (cartproviders.length) {
                if (!global.config.backendshop) {
                    paymentButtons.push(
                        <span key="or2" className="or"><span>{TranslationService.translate('oder andere Zahlungsmittel')}</span></span>
                    )
                }
                paymentButtons.push(
                    <Link key="checkout" to="?path=kasse" className='buttonlink'>
                        <button type="button" className="setorder checkout">{TranslationService.translate('Zur Kasse')}</button>
                    </Link>
                )
            }

        }
    }


    return (
        <>
            {paymentButtons}
        </>
    );

}

export default PaymentButtons;
