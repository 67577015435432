import { useSelector } from "react-redux";
import HelperService from "../../../services/helper.service";
import TranslationService from "../../../services/translation.service";

const OrderTable = ({ products }) => {

    let rows = [];

    rows.push(
        <div key="0" className="pricerow labels">
            <div className="label">{TranslationService.translate('Artikel')}</div>
            <div className="count">{TranslationService.translate('Anzahl')}</div>
            <div className="singleprice">{TranslationService.translate('Einzelpreis')}</div>
            <div className="totalprice">{TranslationService.translate('Gesamtpreis')}</div>
        </div>
    )

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);

    products = HelperService.generateOrderTable(products, useshoppingcart);

    products.forEach(product => {
        rows.push(
            <div key={product.sku} className="pricerow">
                <div className="label">
                    {product.image}
                    <span className="productlabel">{product.name}
                        <span className="hidden-xs">{product.description}</span>
                        {product.appointmenttext ? <span className="small">{product.appointmenttext}</span> : ''}
                        {product.subproducttext ? <span className="small">{product.subproducttext}</span> : ''}
                        {product.shippinginfotext ? <span className="small">{product.shippinginfotext}</span> : ''}
                        {product.templatetext ? <span className="small">{product.templatetext}</span> : ''}
                        {product.messagetext ? <span className="small short" title={product.messagetext}>{product.messagetext}</span> : ''}
                    </span>
                </div>
                <div className="count">{product.quantity}</div>
                <div className="singleprice">{HelperService.formatPrice(product.singleprice)}</div>
                <div className="totalprice">{HelperService.formatPrice(product.totalprice)}</div>
            </div>
        );
    });

    return (
        <>
            <div className="ordertable">{rows}</div>
        </>
    )

}

export default OrderTable;