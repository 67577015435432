import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import ApiService from "../../../services/api.service";
import * as GlobalStore from '../../../store/global/actions';
import * as ShoppingCartStore from '../../../store/shoppingcart/actions';
import { Fragment, useRef, useState } from 'react';
import TranslationService from "../../../services/translation.service";

const ProductPaymentButtons = ({ couponActivePrice, dynamicPrice }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const payments = useSelector(state => state.productpage.payments);
    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const activeshippingprice = useSelector(state => state.productpage.shipping.price);
    const activepackagingprice = useSelector(state => state.productpage.packaging.price);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);
    const cart = useSelector(state => state.cart);
    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const shoppingcart = useSelector(state => state.shoppingcart);
    const coupon = useSelector(state => state.productpage.coupon);
    const activeProductData = useSelector(state => state.productpage.productdata);
    const isDynamicProduct = useSelector(state => state.productpage.data.products.dynamic);

    const overlayRef = useRef(null);
    const [openOverlay, setOpenOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState();



    let paymentButtons = [];
    if (contingent === 0 || (contingent !== null && minqty > contingent)) {
        paymentButtons = <button type="button" className="setorder soldout">{TranslationService.translate('Leider ausverkauft')}</button>
    }

    else {
        let fastcheckoutproviders = [];
        let cartproviders = [];
        function setOrder(e, provider) {
            if (provider.active) {

                if (global.config.preview) {
                    alert('Diese Funktion ist in der Vorschau nicht verfügbar.');
                    return false;
                }

                e.target.setAttribute('disabled', true);

                let botfield1;
                let botfield2;
                if (e.target.form) {
                    botfield1 = e.target.form[0].value;
                    botfield2 = e.target.form[1].value;
                }
                else {
                    botfield1 = e.target.parentElement.form[0].value;
                    botfield2 = e.target.parentElement.form[1].value;
                }
                if (botfield1 !== '' || botfield2 !== 'A17B13') {
                    const API_URL = global.config.express.url + '/botfieldchange';
                    const API_DATA = {
                        'url': window.location.href,
                        'agent': "Geänderte Felder bei Submit erkannt.\n\n" + navigator.userAgent
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        if (response) {
                            navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                        }
                    });
                    return false;
                }

                let payment;

                payment = {
                    'payment-tupel': {
                        'payment-provider': provider['payment-provider'],
                        'payment-method-category': provider['payment-method-category'],
                        'payment-method-value': provider['payment-method-value'],
                        'payment-kind': provider['payment-kind'],
                    }
                };

                Object.assign(cart, payment);

                const API_URL = global.config.express.url + '/cart/transfer';
                const API_DATA = {
                    'shop-url': global.config.apidata.shopurl,
                    'cart': cart,
                    'framepage': window.location.origin + window.location.pathname + '?mode=react',
                    'language': global.config.language
                }

                ApiService.getData(API_URL, API_DATA).then((response) => {

                    if (response.status === 'success') {
                        if (response.url) {
                            window.location.replace(response.url);
                        }
                        if (response.script) {
                            const script = document.createElement('script');
                            script.innerHTML = response.script;
                            document.getElementsByTagName('head')[0].appendChild(script);
                            const overlay = document.createElement('div');
                            overlay.classList.add('gw_overlay');
                            overlay.innerHTML = '<div class="infobox"><h2>' + TranslationService.translate('Amazon Pay') + '</h2><p>' + TranslationService.translate('Mit Amazon Pay können Sie die in Ihrem Amazon-Account hinterlegten Zahlungs- und Versandinformationen nutzen, um schnell und sicher einzukaufen. Zudem genießen Sie den Käuferschutz, den Amazon mit der A-Z Garantie bietet.') + '</p></div>';
                            document.getElementsByTagName('body')[0].appendChild(overlay);
                            document.getElementsByClassName('infobox')[0].appendChild(document.getElementById('AmazonPayButton'));
                            document.getElementById('AmazonPayButton').style.display = 'block';
                            var isMobileSafari = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
                            setTimeout(function () {
                                var infobox = document.getElementsByClassName('infobox')[0];
                                var top = window.innerHeight / 2 - (infobox.offsetHeight / 2 + 50);
                                if (isMobileSafari) {
                                    infobox.style.width = '100%';
                                    infobox.style.left = '0';
                                    infobox.style.top = top + 'px';
                                }
                                else {
                                    var left = window.innerWidth / 2 - infobox.offsetWidth / 2;
                                    infobox.style.left = left + 'px';
                                    infobox.style.top = top + 'px';
                                }
                                infobox.style.opacity = 1;
                            }, 1000);
                        }
                    }

                    else {
                        dispatch(GlobalStore.setApiError(response.message));
                        navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                    }

                });
            }
        }



        const addToShoppingCart = () => {

            let newcart = JSON.parse(JSON.stringify(shoppingcart.shoppingcartproducts));

            if (newcart.length) {

                let cartproductidx = newcart.findIndex((obj => obj.bundlesku === cart.bundlesku));

                // Wenn Produkt schon im Warenkorb ist
                if (cartproductidx !== -1) {

                    // Prüfen ob alles identisch ist                   
                    let producttocheck = newcart[cartproductidx];
                    let identicalmatch = false;
                    // Bundle prüfen (bundlesku | packaging | shipping | msg)
                    if (
                        producttocheck.bundlesku === cart.bundlesku &&
                        producttocheck.packaging === cart.packaging &&
                        producttocheck.shipping === cart.shipping &&
                        producttocheck.msg === cart.msg
                    ) {
                        // Alle Unterprodukte prüfen (sku | activeTemplate | amount)
                        let producttochecksubproducts = [];
                        Array.from(producttocheck.subproducts).forEach((sp) => {
                            producttochecksubproducts.push(sp)
                        })

                        Array.from(cart.subproducts).forEach((sp) => {
                            let cartsubproductidx = producttochecksubproducts.findIndex((obj => obj.sku === sp.sku));
                            if (cartsubproductidx !== -1) {
                                let subproducttocheck = producttochecksubproducts[cartsubproductidx];
                                if (
                                    subproducttocheck.sku === sp.sku &&
                                    subproducttocheck.activeTemplate === sp.activeTemplate &&
                                    subproducttocheck.amount === sp.amount
                                ) {
                                    identicalmatch = true;
                                }
                                else {
                                    identicalmatch = false;
                                }
                            }
                            else {
                                identicalmatch = false;
                            }
                        })
                    }
                    else {
                        identicalmatch = false;
                    }

                    if (identicalmatch) {
                        // Qty erhöhen
                        newcart[cartproductidx].qty = newcart[cartproductidx].qty + cart.qty;
                    }

                    else {
                        // Neues Produkt hinzufügen
                        newcart = [...newcart, cart]
                    }

                }

                else {
                    // Neues Produkt hinzufügen
                    newcart = [...newcart, cart]
                }
            }

            else {
                // Erstes Produkt hinzufügen
                newcart = [cart];
            }


            dispatch(ShoppingCartStore.setShoppingCart(newcart));
            let overlaycontent =
                <>
                    <h2>{TranslationService.translate('Produkt erfolgreich hinzugefügt')}</h2>
                    <p>{TranslationService.translate('Das Produkt befindet sich jetzt im Warenkorb.')}</p>
                    <Link className='button continue-shopping' to="?path=start">{TranslationService.translate('Weiter shoppen')}</Link>
                    <Link to="?path=warenkorb" className="button">{TranslationService.translate('Zum Warenkorb')}</Link>
                </>;
            setOverlayContent(overlaycontent)
            setOpenOverlay(true);

        };


        const getPayments = (fastcheckoutoptions = true) => {
            payments.forEach((payment) => {
                payment['payment-call-tupel'].forEach((item, i) => {
                    if (item['payment-kind'] === 'Order') {
                        item.active = payment.active;
                        fastcheckoutproviders.push(item);
                    }
                    else {
                        cartproviders.push(item);
                    }
                });
            });

            if (fastcheckoutproviders.length && fastcheckoutoptions) {
                paymentButtons.push(
                    <span key="ordircect" className="or"><span>{TranslationService.translate('direkt bezahlen mit')}</span></span>
                )
                fastcheckoutproviders.forEach((provider, key) => {
                    let type = provider['payment-provider'];
                    let active = provider.active ? '' : ' inactive';
                    let paymenticon;
                    if (provider['payment-provider-icon'] && provider['payment-provider-icon']['relative-path']) {
                        paymenticon = <img src={global.config.apidata.url + '/' + provider['payment-provider-icon']['relative-path']} alt={provider['payment-provider-icon']['alt']} />
                    }
                    else {
                        paymenticon = provider['payment-name'];
                    }
                    paymentButtons.push(
                        <button
                            type="button"
                            onClick={(e) => setOrder(e, provider)}
                            className={'setorder ' + type + active}
                            key={key}
                        >
                            {paymenticon}
                        </button>
                    );
                });
            }
        }


        if (isDynamicProduct && dynamicPrice === null) {
            paymentButtons.push(
                <Fragment key="nodynamicprice" >
                    <span className="or"><span>{TranslationService.translate('Wert erforderlich')}</span></span>
                    <label className='t-center'>{TranslationService.translate('Bitte geben Sie einen Wert ein!')}</label>
                </Fragment>
            )
        }

        else {
            // Wenn Preis 0 und Versandkosten 0 || Preis nach Couponeinlösung 0 und Versandkosten 0 
            if ((activeProductData.price <= 0 && activeshippingprice <= 0 && activepackagingprice <= 0) || (couponActivePrice <= 0 && coupon?.shippingpackagingprice <= 0)) {

                let subproductcountselected = false;

                if (activeProductData.subproducts) {
                    activeProductData.subproducts.forEach((sp) => {
                        if (sp.amount >= 1) {
                            subproductcountselected = true;
                        }
                    })
                }

                // Wenn Anzahl an Unterprodukten > 0 || Keine Unterprodukte vorhanden
                if (subproductcountselected || !activeProductData.subproducts) {
                    if (activeProductData.price <= 0) {
                        getPayments(false);
                    }
                    else {
                        getPayments();
                    }
                    if (!useshoppingcart) {
                        paymentButtons.push(
                            <span key="or1" className="or"><span>{TranslationService.translate('direkt bestellen')}</span></span>
                        )
                        paymentButtons.push(
                            <Link key="checkout" to="?path=kasse">
                                <button type="button" className="setorder checkout">{TranslationService.translate('Jetzt bestellen')}</button>
                            </Link>
                        )
                    }
                    else {
                        paymentButtons.push(
                            <span key="or1" className="or"><span>{TranslationService.translate('oder')}</span></span>
                        )
                        paymentButtons.push(
                            <button key="addtoshoppingcart" type="button" className="setorder addtoshoppingcart" onClick={() => addToShoppingCart()}>{TranslationService.translate('In den Warenkorb')}</button>
                        )
                    }
                }
                else {
                    paymentButtons.push(
                        <Fragment key="noproduct" >
                            <span className="or"><span>{TranslationService.translate('Menge erforderlich')}</span></span>
                            <label className='t-center'>{TranslationService.translate('Bitte mindestens ein Produkt auswählen!')}</label>
                        </Fragment>
                    )
                }
            }

            else {

                // Wenn Preis 0 aber Versandkosten != 0
                if ((activeProductData.price <= 0 && (activeshippingprice > 0 || activepackagingprice > 0))) {
                    let subproductcountselected = false;
                    if (activeProductData.subproducts) {
                        activeProductData.subproducts.forEach((sp) => {
                            if (sp.amount >= 1) {
                                subproductcountselected = true;
                            }
                        })
                    }

                    // Wenn Anzahl an Unterprodukten > 0 || Keine Unterprodukte vorhanden
                    if (subproductcountselected || !activeProductData.subproducts) {

                        getPayments();
                        if (!useshoppingcart) {
                            paymentButtons.push(
                                <span key="or1" className="or"><span>{TranslationService.translate('direkt bestellen')}</span></span>
                            )
                            paymentButtons.push(
                                <Link key="checkout" to="?path=kasse">
                                    <button type="button" className="setorder checkout">{TranslationService.translate('Jetzt bestellen')}</button>
                                </Link>
                            )
                        }
                        else {
                            paymentButtons.push(
                                <span key="or1" className="or"><span>{TranslationService.translate('oder')}</span></span>
                            )
                            paymentButtons.push(
                                <button key="addtoshoppingcart" type="button" className="setorder addtoshoppingcart" onClick={() => addToShoppingCart()}>{TranslationService.translate('In den Warenkorb')}</button>
                            )
                        }
                    }
                    else {
                        paymentButtons.push(
                            <Fragment key="noproduct" >
                                <span className="or"><span>{TranslationService.translate('Menge erforderlich')}</span></span>
                                <label className='t-center'>{TranslationService.translate('Bitte mindestens ein Produkt auswählen!')}</label>
                            </Fragment>
                        )
                    }
                }

                else {
                    getPayments();
                    if (!useshoppingcart) {
                        if (cartproviders.length) {
                            if (fastcheckoutproviders.length) {
                                paymentButtons.push(
                                    <span key="or2" className="or"><span>{TranslationService.translate('oder andere Zahlungsmittel')}</span></span>
                                )
                            }
                            else {
                                paymentButtons.push(
                                    <span key="or2" className="or"><span>{TranslationService.translate('Jetzt bezahlen')}</span></span>
                                )
                            }
                            paymentButtons.push(
                                <Link key="checkout" to="?path=kasse">
                                    <button type="button" className="setorder checkout">{TranslationService.translate('Zur Kasse')}</button>
                                </Link>
                            )
                        }
                    }
                    else {
                        if (!global.config.backendshop) {
                            paymentButtons.push(
                                <span key="or2" className="or"><span>{TranslationService.translate('oder')}</span></span>
                            )
                        }
                        paymentButtons.push(
                            <button key="addtoshoppingcart" type="button" className="setorder addtoshoppingcart" onClick={() => addToShoppingCart()}>{TranslationService.translate('In den Warenkorb')}</button>
                        )

                        // Wenn alle verfügbaren Produkte (Kontingent) bereits im Warenkorb sind
                        if (useshoppingcart && activeProductData.contingent) {

                            let productcontingent = activeProductData.contingent;
                            let match = false;

                            let activeproductsubproducts = [];
                            activeProductData.subproducts.forEach((sp) => {
                                activeproductsubproducts.push(sp);
                            });

                            // Check Shoppingcart QTYs
                            let cartreservedcontingents = [];

                            shoppingcart.shoppingcartproducts.forEach((cartproduct) => {
                                Object.values(cartproduct.subproducts).forEach((cp) => {
                                    let cartproductidx = activeproductsubproducts.findIndex((obj => obj.sku === cp.sku));

                                    if (cartproductidx !== -1) {
                                        // Alle Produkte im Warenkorb zusammenzählen:
                                        let cartproductqty = cartproduct.qty * cp.amount;
                                        cartreservedcontingents[cp.sku] = (cartreservedcontingents[cp.sku] ? cartreservedcontingents[cp.sku] : 0) + cartproductqty
                                    }
                                });
                            })

                            Object.keys(cartreservedcontingents).forEach(function (key, index) {
                                let cartamount = cartreservedcontingents[key];
                                if (productcontingent <= cartamount) {
                                    match = true;
                                }
                            });

                            if (match) {
                                // reset buttons
                                paymentButtons = [];
                                paymentButtons.push(
                                    <Fragment key="nodynamicprice" >
                                        <span className="or"><span>{TranslationService.translate('Ausverkauft')}</span></span>
                                        <label className='t-center'>{TranslationService.translate('Das letzte Produkt ist bereits im Warenkorb!')}</label>
                                    </Fragment>
                                )
                            }
                        }

                    }
                }

            }
        }

    }

    return (
        <>
            {paymentButtons}
            {openOverlay ?
                <div className="gw_slide_overlay">
                    <div className="infobox" ref={overlayRef}>
                        {overlayContent}
                    </div>
                </div>
                : ''}
        </>
    );


}

export default ProductPaymentButtons;
