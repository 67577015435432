import { useSelector } from 'react-redux';
import HelperService from "../../../services/helper.service";
import TranslationService from "../../../services/translation.service";

const ProductDiscount = () => {

    const value = useSelector(state => state.productpage.productdata.value);
    const price = useSelector(state => state.productpage.productdata.price);
    const amount = useSelector(state => state.productpage.amount);
    const speech = useSelector(state => state.global.speech);

    let discount = value - price > 0 ? value - price : 0;
    let discountHtml;
    if (discount !== 0) {
        let discountpercent = Math.round((100 / value) * discount);
        let discountspeech = speech === 'informal' ? TranslationService.translate('Du sparst') : TranslationService.translate('Sie sparen');
        discountHtml = <span className="discount">{discountpercent}% {TranslationService.translate('Rabatt')} ({discountspeech} {HelperService.formatPrice(discount * amount)})</span>
    }

    return (
        <>
            {discountHtml}
        </>
    );

}

export default ProductDiscount;
