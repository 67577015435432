import { useSelector } from 'react-redux';
import HelperService from "../../../services/helper.service";
import TranslationService from "../../../services/translation.service";

const ProductCouponInfo = () => {

    const discount = useSelector(state => state.productpage.coupon);

    let discountHtml;
    if (discount && typeof (discount.value) !== 'undefined') {
        discountHtml = <div className="couponinfo">{TranslationService.translate('Aktionscode')}: <strong>{HelperService.formatPrice(discount.value)}</strong> {TranslationService.translate('Zusatzrabatt')}</div>;
    }

    return (
        <>
            {discountHtml}
        </>
    );

}

export default ProductCouponInfo;
