import OrderBoxes from './elements/OrderBoxes';
import OrderPrintButton from './elements/OrderPrintButton';
import OrderText from './elements/OrderText';
import TranslationService from "../../services/translation.service";

import { useState, useEffect } from 'react';

const OrderSuccessContent = ({ cart, successDownload }) => {

    const [products, setProducts] = useState();

    useEffect(() => {
        if (typeof (cart) !== 'undefined') {
            setProducts(cart['order-product-bundle-groups']);
        }
    }, [cart]);

    if (products) {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                        <h1>{TranslationService.translate('Bestellung abgeschlossen')}</h1>
                        <p>{TranslationService.translate('Die Bestellung war erfolgreich!')}</p>
                    </div>
                </div>
                <div className="row orderboxes">
                    <OrderBoxes products={products} />
                </div>
                <div className="row">
                    <br /><br />
                    <OrderPrintButton link={successDownload} />
                    <OrderText cart={cart} />
                </div>
            </>
        );
    }

}


export default OrderSuccessContent;
