import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

import TranslationService from "../../../services/translation.service";

const ProductBox = ({ product }) => {

    const activecat = useSelector(state => state.startpage.activecategory);
    const showcats = useSelector(state => state.startpage.showcategories);
    const categorystate = useSelector(state => state.startpage.categorystate);
    let parentcategory = product['superior-category-identifier']
    let category = (parentcategory ? parentcategory + '-' : '') + product['category-identifier'];
    let multishopidentifier = product['multi-shop-identifier'] ? product['multi-shop-identifier'] + '/' : '';
    let topproduct = product['top-product'];
    let infobox = product['infobox-label'];
    let orderable = product.orderable;
    let boxsize = showcats && categorystate === 'visible' ? 'col-lg-4 col-md-4 col-sm-4 col-xs-6 productview ' : 'col-lg-3 col-md-3 col-sm-4 col-xs-6 productview ';
    let link = orderable ? '?path=' + multishopidentifier + product['category-identifier'] + '/' + product.identifier : false;
    let mainimage = product.mainimage;
    mainimage = mainimage ? <LazyLoadImage src={global.config.apidata.url + '/' + mainimage['relative-path']} alt={mainimage['alt']} /> : <LazyLoadImage src={global.config.express.url + '/no-productpic.jpg'} alt="Kein Bild vorhanden" />
    infobox = infobox ? infobox.charAt(0).toUpperCase() + infobox.slice(1) : '';
    let purepic = <span className="pic">{mainimage}{infobox || topproduct ? <span className="info">{topproduct ? 'Bestseller' : infobox}</span> : ''}</span>;
    let pic = link ? <Link to={link}>{purepic}</Link> : purepic;

    return (
        <div key={product.id} className={boxsize + category + (link ? '' : ' soldout') + (activecat === category || activecat === parentcategory || activecat === 'all' ? ' visible' : ' hidden')}>
            <div className='productbox'>
                {pic}
                <div className="inner top">
                    <h3>{product.name}</h3>
                    <p>{product['description-short']}</p>
                </div>
                <div className='boxbottom'>
                    <div className="inner">
                        <div className="price">
                            <span className="final">{product['price-label']}</span>
                            <span className="discountlabel">{product['discount-label']}</span>
                            <span className="infos">{TranslationService.translate("inkl. MwSt. | zzgl. Versand")}</span>
                        </div>
                    </div>
                    {link ? <Link className='link' to={link}>{product['button-label']}</Link> : <span className='link grey'>{infobox}</span>}
                </div>
            </div>
        </div>
    );
}

export default ProductBox;
