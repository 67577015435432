import HelperService from "../../../services/helper.service";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';
import TranslationService from "../../../services/translation.service";

const ProductOptionsChoose = ({ type, setselect, setCouponActivePrice }) => {

    const dispatch = useDispatch();
    const products = useSelector(state => state.productpage.products);
    const productid = useSelector(state => state.productpage.productdata.id);

    function changeStates(product) {

        dispatch(ProductPageStore.setProductData({
            'id': product.id,
            'name': product.name,
            'description': product.description,
            'sku': product.sku,
            'value': product['product-price']['gross-amount-value'].amount,
            'price': product['product-price']['gross-amount'].amount,
            'contingent': product['available-contingent'],
            'minimum-quantity': product['minimum-quantity'],
            'templates': product['media-templates'],
        }));

        dispatch(ProductPageStore.setAmount(product['minimum-quantity']));
        dispatch(CartStore.setCartSku(product.sku));
        dispatch(CartStore.setCartQty(product['minimum-quantity']));
        dispatch(ProductPageStore.setCoupon());
        dispatch(CartStore.setCartCouponCode(null));
        setCouponActivePrice(null);

        // Wenn Einzelprodukt 'distribution-products' enthält
        if (product['distribution-products'].length > 0) {
            dispatch(ProductPageStore.setDistributionProducts(product['distribution-products']));
            let firstpackaging = product['distribution-products'][0]['shipping-packaging'];
            let firstpackagingprice = firstpackaging['price']['gross-amount']['amount'];
            let firstpackagingsliderimage = firstpackaging['packaging-logo'] ? firstpackaging['packaging-logo'] : null;
            let firstshipping = firstpackaging['shipping-method-products'][0];
            let firstshippingprice = firstshipping['price']['gross-amount']['amount'];

            dispatch(ProductPageStore.setPackaging({
                'id': firstpackaging.id,
                'name': firstpackaging.description,
                'price': firstpackagingprice,
                'image': firstpackagingsliderimage
            }));

            dispatch(ProductPageStore.setShipping({
                'id': firstshipping.id,
                'name': firstshipping.description,
                'price': firstshippingprice,
                'type': firstshipping.type
            }));

            dispatch(CartStore.setCartPackaging(firstpackaging.id));
            dispatch(CartStore.setCartShipping(firstshipping.id));

        }

        if (setselect) {
            setselect('hidden');
            if (typeof (document.getElementsByClassName('gw_overlay')[0]) !== 'undefined') {
                document.getElementsByClassName('gw_overlay')[0].remove();
            }
        }
    }

    if (products) {
        const productCooseBoxes = products.map((product) => (
            <button
                type="button"
                onClick={() => changeStates(product)}
                key={product.id}
                className={(productid === product.id ? 'activ' : '')}>{HelperService.formatPrice(product['product-price']['gross-amount-value'].amount)}
            </button>
        ));


        let view;

        if (type === 'select') {
            view =
                <>
                    {productCooseBoxes}
                </>
        }
        else {
            view =
                <div className="hidden-md hidden-sm hidden-xs">
                    <h3>{TranslationService.translate('Optionen')}</h3>
                    <div className="checkboxes options">
                        {productCooseBoxes}
                    </div>
                </div>
        }


        return (
            <>
                {view}
            </>
        );
    }

}

export default ProductOptionsChoose;
