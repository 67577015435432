
import TranslationService from "../../../services/translation.service";

const OrderPrintButton = ({ link }) => {

    if (link) {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                <a className="button" href={link} target="_blank" rel="noreferrer">{TranslationService.translate('PDF ausdrucken')}</a>
            </div>
        )
    }

}

export default OrderPrintButton;