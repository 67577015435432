import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import TranslationService from "../../../services/translation.service";

const OrderBoxes = ({ products }) => {

    let boxes = [];
    products.forEach(group => {
        group['order-product-bundles'].forEach(bundle => {
            bundle['order-products'].forEach(product => {
                if (product['identification-number']) {
                    boxes.push(
                        <div key={product['identification-number']} className="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-box t-center">
                            <h3>{product.name}</h3>
                            <p className="appointment">{product.appointment?.name ? product.appointment.name : ''}</p>
                            <p>{TranslationService.translate('Code')} <span>{product['identification-number']}</span></p>
                            <QRCode className="qrcode" value={product['identification-number']} bgColor={"transparent"} />
                            <Barcode className="barcode" value={product['identification-number']} displayValue={false} background={"transparent"} format={"CODE128C"} width={3} margin={0} />
                        </div>
                    )
                }
            });
        });
    });

    return (
        boxes
    )

}

export default OrderBoxes;