import HelperService from "../../../services/helper.service";
import TranslationService from "../../../services/translation.service";

const Summary = ({ cart }) => {

    let totalvalue = cart['cart-price-total']['gross-amount-value'].amount;
    let total = cart['cart-price']['gross-amount'].amount;

    let originalvalue = cart['cart-price-original']['gross-amount-value'].amount;
    let originalprice = cart['cart-price-original']['gross-amount'].amount;

    let discountvalue;
    let discountpercent;
    if (originalvalue > originalprice) {
        discountvalue = originalvalue - originalprice;
        discountpercent = Math.round(100 - (100 / originalvalue) * originalprice)
    }

    let couponvalue = cart['coupon-balance'] !== null ? cart['coupon-balance']['used-balance-amount'].amount : '';
    let packagingprice = cart['cart-packaging-price']['gross-amount-value'].amount;
    let shippingprice = cart['cart-method-price']['gross-amount-value'].amount;

    let taxes = [];

    Object.values(cart['cart-price']['tax-amounts']).forEach(tax => {
        if (tax['vat-value'] !== 0) {
            taxes.push(
                <div key={tax['vat-type']} className="pline">
                    {TranslationService.translate('inkl.') + ' ' + tax['vat-value'] + '% ' + TranslationService.translate('MwSt.') + ':'}
                    <span>{HelperService.formatPrice(tax['vat-amount'].amount)}</span>
                </div>
            )
        }
    });

    return (
        <div className="summary">
            <div className="pline sum">{TranslationService.translate('Summe')}: <span>{HelperService.formatPrice(totalvalue)}</span></div>
            {discountvalue ? <div className="pline">{TranslationService.translate('Rabatt')} ({discountpercent}%): <span>- {HelperService.formatPrice(discountvalue)}</span></div> : ''}
            {couponvalue ? <div className="pline">{TranslationService.translate('Aktionscode')}: <span>- {HelperService.formatPrice(couponvalue)}</span></div> : ''}
            <div className="pline">{TranslationService.translate('Verpackung')}: <span>{HelperService.formatPrice(packagingprice)}</span></div>
            <div className="pline">{TranslationService.translate('Versand')}: <span>{HelperService.formatPrice(shippingprice)}</span></div>
            {taxes}
            <div className="pline all">{TranslationService.translate('Gesamtsumme')}: <span>{HelperService.formatPrice(total)}</span></div>
        </div>
    )

}

export default Summary;