import { useRef, useState } from "react";
import HelperService from "../../../services/helper.service";
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import ProductOptionsChoose from './ProductOptionsChoose';
import TranslationService from "../../../services/translation.service";

import { useSelector } from 'react-redux';


const ProductFormOptionsChoose = ({ setCouponActivePrice }) => {

    const activeProductData = useSelector(state => state.productpage.productdata);
    const [optionsSelect, setOptionsSelect] = useState('hidden');

    const wrapperRef = useRef(null);
    HideOpenedSelect(wrapperRef, optionsSelect, setOptionsSelect);

    function changeState() {
        setOptionsSelect('visible');
        if (window.innerWidth < 768) {
            var overlay = document.createElement('div');
            overlay.className = 'gw_overlay';
            document.body.appendChild(overlay);
            document.getElementById('shop').appendChild(overlay);
        }
    }

    function closeOptions() {
        setOptionsSelect('hidden');
        if (typeof (document.getElementsByClassName('gw_overlay')[0]) !== 'undefined') {
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }


    return (
        <>
            <div className="input-holder" ref={wrapperRef}>
                <label>{TranslationService.translate('Produktauswahl')}:</label>
                <input onClick={() => changeState()} className="selections value" type="text" value={HelperService.formatPrice(activeProductData.value)} autoComplete="off" readOnly />
                <div className={optionsSelect + " select_options"}>
                    <div className="hl hidden visible-xs"><span className="label">{TranslationService.translate('Auswahl')}</span><span onClick={() => closeOptions()} className="close">x</span></div>
                    <ProductOptionsChoose type="select" setCouponActivePrice={setCouponActivePrice} setselect={setOptionsSelect} />
                </div>
            </div>
        </>
    )

}

export default ProductFormOptionsChoose;