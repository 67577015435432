import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import OrderBoxes from './ordersuccesspage/elements/OrderBoxes';
import OrderPrintButton from './ordersuccesspage/elements/OrderPrintButton';
import TranslationService from "../services/translation.service";

const OrderInfoPage = () => {

    const [successCart, setSuccessCart] = useState();
    const [successDownload, setSuccessDownload] = useState();
    const [productCount, setProductCount] = useState(false);
    const [additionalText, setAdditionalText] = useState();

    const queryParameters = new URLSearchParams(window.location.search);
    const identifier = queryParameters.get("transitIdentifier");
    const singlecode = queryParameters.get("code");

    const [error, setError] = useState(null);
    if (error) throw error;

    useEffect(() => {
        if (identifier) {
            const API_URL = global.config.express.url + '/cart/details';
            const API_DATA = {
                'transit-identifier': identifier,
                'code': singlecode,
                'shop-url': global.config.apidata.shopurl,
                'language': global.config.language
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    setSuccessCart(response.cart);
                    setSuccessDownload(response.download);
                    setProductCount(response.productcount);
                    setAdditionalText(response.cart['order-product-bundle-groups'][0]['order-product-bundles'][0]['order-products'][0]['additional-text'])
                }
            }).catch((error) => { setError(error) });
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier, singlecode]);

    let cont;
    if (successCart) {
        if (productCount > 1) {
            cont =
                <div className="container nopadding t-center">
                    <h1>{TranslationService.translate('Bestellinformationen')}</h1>
                    <p>{additionalText}</p>
                    <p>{TranslationService.translate('Der Code befindet sich direkt unter dem Barcode, der soeben eingescannt wurde.')}</p>
                    <p style={{ 'fontSize': '14px' }}><br />{TranslationService.translate('Beispielansicht:')}<br /><span className="codeexample"></span></p>
                </div>
                ;
        }
        else {
            cont =
                <div className="container nopadding t-center">
                    <h1>{TranslationService.translate('Bestellinformationen')}</h1>
                    <p>{additionalText}</p>
                    <h2>{TranslationService.translate('Hier ist das gekaufte Produkt')}</h2>
                    <div className="row orderboxes">
                        <OrderBoxes products={successCart['order-product-bundle-groups']} />
                    </div>
                    <div className="row">
                        <br /><br />
                        <OrderPrintButton link={successDownload} productCount={productCount} />
                    </div>
                </div>
                ;
        }
    }

    return cont;

}

export default OrderInfoPage;
