import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as StartPageStore from '../../store/startpage/actions';
import TranslationService from "../../services/translation.service";

const ProductCategories = () => {

    const dispatch = useDispatch();

    const catheadline = TranslationService.translate('Kategorien');
    const filterbuttext = TranslationService.translate('Filtern');

    const categories = useSelector(state => state.startpage.allcategories);
    const activecat = useSelector(state => state.startpage.activecategory);
    const categorystate = useSelector(state => state.startpage.categorystate);
    const [toggleState, setToggleState] = useState(false);
    const [navHeadline, setNavHeadline] = useState(catheadline);
    const [navParent, setNavParent] = useState();
    const [navBack, setNavBack] = useState();

    const navBox = useRef(null);

    useEffect(() => {
        dispatch(StartPageStore.setCategoryState('visible'));
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("cat");
        if (path) {
            let navButton = document.getElementsByClassName(path)[1];
            if (typeof (navButton) !== 'undefined') {
                let navname = document.getElementsByClassName(path)[1].innerHTML;
                dispatch(StartPageStore.setActiveCategoryName(navname));
                dispatch(StartPageStore.setActiveCategory(path));
                if (navButton.parentNode.parentNode.parentNode.classList.contains('subnav') && window.innerWidth > 991) {
                    let parentElement = navButton.parentNode.parentNode.parentNode;
                    let parentheadline = parentElement.firstChild.innerHTML;
                    let parentidentifier = parentElement.firstChild.classList[0];
                    setNavHeadline(parentheadline);
                    setNavParent(parentidentifier);
                    setNavBack(true);
                    Object.values(document.getElementsByClassName('l1')[0].children).forEach(child => {
                        if (!child.classList.contains(parentidentifier)) {
                            child.style.display = 'none';
                        }
                        else {
                            child.children[0].style.display = 'none';
                            child.children[1].style.display = 'block';
                        }
                    })
                }
            }
        }
    }, [dispatch]);

    function navGoBack() {
        Object.values(document.getElementsByClassName('l1')[0].children).forEach(child => {
            if (!child.classList.contains(navParent)) {
                child.style.display = 'block';
            }
            else {
                child.children[0].style.display = 'block';
                child.children[1].style.display = 'none';
            }
        })
        dispatch(StartPageStore.setActiveCategory(navParent));
        dispatch(StartPageStore.setActiveCategoryName(navHeadline));
        setNavHeadline(catheadline);
        setNavParent();
        setNavBack(false);
    }


    function changeStates(identifier, name, subnav) {
        dispatch(StartPageStore.setActiveCategory(identifier));
        dispatch(StartPageStore.setActiveCategoryName(name));
        document.getElementsByClassName('filtercont')[0].classList.remove('opened');
        if (window.innerWidth > 991) {
            if (subnav) {
                setNavHeadline(name);
                setNavParent(identifier);
                setNavBack(true);
                Object.values(document.getElementsByClassName('l1')[0].children).forEach(child => {
                    if (!child.classList.contains(identifier)) {
                        child.style.display = 'none';
                    }
                    else {
                        child.children[0].style.display = 'none';
                        child.children[1].style.display = 'block';
                    }
                })
            }
            if (!subnav && !navParent) {
                setNavHeadline(catheadline);
                setNavParent();
                setNavBack(false);
            }
        }
    }

    function changeToggleState() {
        if (categorystate === 'visible' && toggleState !== true) {
            dispatch(StartPageStore.setCategoryState('hidden'));
        }
        else {
            dispatch(StartPageStore.setCategoryState('visible'));
            setToggleState(false);
        }
    }

    function toggleNavbar(e) {
        if (window.innerWidth > 991) {
            let cssLeft = navBox.current.clientWidth + navBox.current.offsetParent.offsetLeft - e.target.clientWidth;
            let togglestate = e.target.className.split(' ')[1];
            if (togglestate === 'opened') {
                e.target.classList.remove(togglestate);
                navBox.current.style.left = '-' + (cssLeft + 15) + 'px';
            }
            else {
                setToggleState(true);
                dispatch(StartPageStore.setCategoryState('visible'));
                e.target.classList.add('opened');
                navBox.current.style.left = '0';
            }
        }
        else {
            let togglestate = e.target.nextSibling.className.split(' ')[1];
            if (togglestate !== 'opened') {
                e.target.nextSibling.classList.add('opened');
            }
            else {
                e.target.nextSibling.classList.remove(togglestate);
            }
        }
    }

    let categoryBoxes = [];
    let subelements = [];

    categories.forEach(category => {
        if (category.parent !== null) {
            subelements[category.parent] = subelements[category.parent] ? subelements[category.parent] : [];
            subelements[category.parent].push(
                <li key={category.parent + '-' + category.identifier} className={category.identifier}>
                    <button onClick={() => changeStates(category.parent + '-' + category.identifier, category.name, false)} className={category.identifier + (activecat === category.identifier ? ' active' : '')}>{category.name}</button>
                </li>
            )
        }
    });

    categories.forEach(category => {
        if (category.parent === null) {
            categoryBoxes.push(
                <li key={category.identifier} className={category.identifier + (subelements[category.identifier] ? ' subnav' : '')}>
                    <button onClick={() => changeStates(category.identifier, category.name, subelements[category.identifier] ? true : false)} className={category.identifier + (activecat === category.identifier ? ' active' : '')}>{category.name}</button>
                    {subelements[category.identifier] ? <ul> {subelements[category.identifier]} </ul> : ''}
                </li>
            );
        }
    });

    return (
        <div onTransitionEnd={changeToggleState} className={"filter " + categorystate} ref={navBox}>
            <button type="button" onClick={(e) => toggleNavbar(e)} className="filterbut opened">{window.innerWidth < 992 ? filterbuttext : ''}</button>
            <div className="filtercont">
                <h3>{navBack ? <button onClick={() => navGoBack()} className="navback">{navHeadline}</button> : navHeadline}</h3>
                <nav>
                    <ul className="l1">
                        {categoryBoxes}
                    </ul>
                </nav>
            </div>
        </div>
    );

}

export default ProductCategories;
