import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';
import TranslationService from "../../../services/translation.service";

const ProductSelectOptionsAppointment = ({ type, mobile, setCouponActivePrice, setselect = '' }) => {

    const dispatch = useDispatch();

    const appointments = useSelector(state => state.productpage.appointmentproducts);
    const productdata = useSelector(state => state.productpage.productdata);
    const activekey = useSelector(state => state.productpage.appointment.name);
    const products = useSelector(state => state.productpage.products);

    function changeAppointmentState(appointment) {

        productdata.subproducts.forEach(subproduct => {
            let idx = products.findIndex((obj => obj.id === subproduct.id));
            let minqty = products[idx]['minimum-quantity'];
            subproduct.amount = minqty;
        });

        dispatch(ProductPageStore.setAppointment(appointment));
        if (appointments[0].products.length < 2) {
            let newdata = productdata;
            newdata.contingent = appointment.products[0].contingent;
            dispatch(ProductPageStore.setProductData(newdata));
        }
        dispatch(CartStore.setCartAppointment(appointment));
        setCouponActivePrice(null);
        setselect('hidden');

        if (typeof (document.getElementsByClassName('gw_overlay')[0]) !== 'undefined') {
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }

    const appointmentBoxes = appointments.map((a) => {
        if (a) {
            let name = a.name;
            let selectoption = name;
            return (
                <button
                    type="button"
                    onClick={() => changeAppointmentState(a)}
                    className={(activekey === name ? 'activ' : '')}
                    key={name}
                >
                    {selectoption}
                </button>
            )
        }
        else {
            return false;
        }
    });

    let view;
    if (type === 'select') {
        view =
            <>
                {appointmentBoxes}
            </>
    }
    else {
        let classes = 'boxview';
        if (mobile !== 'yes') {
            classes = "hidden-md hidden-sm hidden-xs";
        }

        view =
            <div className={classes}>
                <h3>{TranslationService.translate('Terminauswahl')}</h3>
                <div className="checkboxes appointments">
                    {appointmentBoxes}
                </div>
            </div>
    }
    return (
        <>
            {view}
        </>
    );

}

export default ProductSelectOptionsAppointment;
