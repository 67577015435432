import { useSelector } from 'react-redux';
import TranslationService from "../../../services/translation.service";

const ProductContingent = () => {

    const product = useSelector(state => state.productpage.productdata);
    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const shoppingcart = useSelector(state => state.shoppingcart);

    let availablecontingent = contingent;

    // Check Shoppingcart QTYs
    if (useshoppingcart && contingent) {
        let productcontingent = contingent;

        let cartreservedcontingents = [];

        shoppingcart.shoppingcartproducts.forEach((cartproduct) => {
            Object.values(cartproduct.subproducts).forEach((cp) => {
                if (product.sku === cp.sku) {
                    // Alle identischen Produkte im Warenkorb zusammenzählen:
                    let cartproductqty = cartproduct.qty * cp.amount;
                    cartreservedcontingents[cp.sku] = (cartreservedcontingents[cp.sku] ? cartreservedcontingents[cp.sku] : 0) + cartproductqty
                }
            });
        })

        Object.keys(cartreservedcontingents).forEach(function (key, index) {
            let cartamount = cartreservedcontingents[key];
            availablecontingent = productcontingent - cartamount;
            if (availablecontingent <= 0) {
                availablecontingent = 0;
            }
        });
    }


    let contingentHtml;
    if (contingent !== null) {
        let soldout = availablecontingent === 0 || minqty > availablecontingent ? ' soldout' : '';
        let contingentText = availablecontingent > 0 && minqty <= availablecontingent ? TranslationService.translate('Nur noch') + ' ' + availablecontingent + ' ' + TranslationService.translate('Stück verfügbar') : TranslationService.translate("nicht mehr verfügbar");
        contingentHtml = contingentText = availablecontingent < 100 ? <span className={"count" + soldout}>{contingentText}</span> : '';
    }

    return (
        <>
            {contingentHtml}
        </>
    );

}

export default ProductContingent;
