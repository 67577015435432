import HelperService from "../../services/helper.service";
import TranslationService from "../../services/translation.service";

const CheckoutSummaryBox = ({ cart }) => {

    let total = cart['cart-price']['gross-amount'].amount;

    let originalvalue = cart['cart-price-original']['gross-amount-value'].amount;
    let originalprice = cart['cart-price-original']['gross-amount'].amount;

    let discountvalue;
    let discountpercent;
    if (originalvalue > originalprice) {
        discountvalue = originalvalue - originalprice;
        discountpercent = Math.round(100 - (100 / originalvalue) * originalprice)
    }

    let couponvalue = cart['coupon-balance'] !== null ? cart['coupon-balance']['used-balance-amount'].amount : '';
    let packagingprice = cart['cart-packaging-price']['gross-amount-value'].amount;
    let shippingprice = cart['cart-method-price']['gross-amount-value'].amount;

    let productlist = [];

    let products = HelperService.generateOrderTable(cart['order-product-bundle-groups']);
    products.forEach(p => {
        let price = HelperService.formatPrice(p.totalprice);
        let qty = p.quantity > 1 ? p.quantity + 'x ' : '';

        productlist.push(
            <div key={p.sku} className="pline sum">
                <span className="rti">{qty}{p.name}</span>
                <span className="sum">{price}</span>
            </div>
        )
    });

    let taxes = [];
    Object.values(cart['cart-price']['tax-amounts']).forEach(tax => {
        if (tax['vat-value'] !== 0) {
            taxes.push(
                <div key={tax['vat-type']} className="pline">
                    <span className="rti">{TranslationService.translate('inkl.')} {tax['vat-value']}% {TranslationService.translate('MwSt.')}: </span>
                    <span className="sum">{HelperService.formatPrice(tax['vat-amount'].amount)}</span>
                </div>
            )
        }
    });

    return (
        <div className="shortsummaryinfos">
            {productlist}
            {discountvalue ? <div className="pline"><span className="rti">{TranslationService.translate('Rabatt')} ({discountpercent}%): </span><span className="sum">- {HelperService.formatPrice(discountvalue)}</span></div> : ''}
            {couponvalue ? <div className="pline"><span className="rti">{TranslationService.translate('Aktionscode')}: </span><span className="sum">- {HelperService.formatPrice(couponvalue)}</span></div> : ''}
            <div className="pline"><span className="rti">{TranslationService.translate('Verpackung')}: </span><span className="sum">{HelperService.formatPrice(packagingprice)}</span></div>
            <div className="pline"><span className="rti">{TranslationService.translate('Versand')}: </span><span className="sum">{HelperService.formatPrice(shippingprice)}</span></div>
            {taxes}
            <div className="pline all"><span className="rti">{TranslationService.translate('Gesamtsumme')}: </span><span className="sum">{HelperService.formatPrice(total)}</span></div>
        </div>
    )

}

export default CheckoutSummaryBox;