const initialState = {
  shoppingcartproducts: [],
  shoppingcartcouponcode: null
};


const Reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET_SHOPPINGCART': {
      return initialState;
    }

    case 'SET_SHOPPINGCART': {
      return {
        ...state,
        // shoppingcartproducts: [...state.shoppingcartproducts, action.cartproductsdata]
        shoppingcartproducts: action.cartproductsdata
      };
    }

    case 'REMOVE_SHOPPINGCARTPRODUCT': {

      let newCart = state.shoppingcartproducts.map((v, i) => ({ v, i })).filter(t => t.i !== action.index)
      let cartProducts = [];
      newCart.forEach(p => {
        cartProducts.push(p.v)
      })

      return {
        ...state,
        shoppingcartproducts: cartProducts
      };
    }

    case 'SET_SHOPPINGCARTCOUPONCODE': {
      return {
        ...state,
        shoppingcartcouponcode: action.shoppingcartcouponcode,
      };
    }

    case 'SET_FINALSHOPPINGCART': {
      return {
        ...state,
        finalshoppingcart: action.finalshoppingcart,
      };
    }

    default: {
      return state;
    }
  }
};

export default Reducer;
