import { useSelector } from 'react-redux';
import ProductTemplateButton from './ProductTemplateButton';
import TranslationService from "../../../services/translation.service";

const ProductOptionsAll = () => {

    const products = useSelector(state => state.productpage.products);

    if (products) {

        let elements = [];
        products.forEach((p, i) => {
            elements.push(
                <li key={i}>{p['minimum-quantity']}x  {p.name} <br /><ProductTemplateButton productId={p.id} templateCount={p['media-templates'].length} subnum={i} notype={true} /></li>
            )
        });

        return (
            <>
                <div className="included-products">
                    <h3>{TranslationService.translate('Dieses Paket beinhaltet:')}</h3>
                    <ul>{elements}</ul>
                </div>
            </>
        );

    }

}

export default ProductOptionsAll;
