import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as CartStore from '../../../store/cart/actions';
import TranslationService from "../../../services/translation.service";

const ProductPersonalMessage = () => {

    const dispatch = useDispatch();
    const message = useSelector(state => state.productpage.greetingtext);
    const speech = useSelector(state => state.global.speech);
    const [textCount, setTextCount] = useState(200);

    function changeText(e) {
        setTextCount(maxTextCount - e.target.value.length);
        dispatch(CartStore.setCartPersMsg(e.target.value));
    }

    function togglePersonalMessage(e) {
        let messagecontainer = e.target.nextSibling;
        if (messagecontainer.classList.contains('opened')) {
            messagecontainer.classList.remove('opened');
        }
        else {
            messagecontainer.classList.add('opened');
        }

    }

    let maxTextCount = 200;
    let messagespeech = speech === 'informal' ? TranslationService.translate('Deine') : TranslationService.translate('Ihre');

    return (
        <>
            <button onClick={(e) => togglePersonalMessage(e)} className="button hidden visible-xs personalize">{TranslationService.translate('Produkt personalisieren')}</button>
            <div className={"personal_message"}>
                <label>{messagespeech} {TranslationService.translate('personalisierte Nachricht')}</label>
                <textarea onChange={e => changeText(e)} maxLength="200" placeholder={message} className="pers_msg"></textarea>
                <div className="count_wrap">{TranslationService.translate('Noch')} <span className="counter">{textCount}</span> {TranslationService.translate('von')} {maxTextCount} {TranslationService.translate('Zeichen verfügbar')}</div>
            </div>
        </>
    );

}

export default ProductPersonalMessage;
