import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import TranslationService from "../services/translation.service";

const ContentPage = () => {

    const [data, setData] = useState();

    let contentsection;
    let headline;

    const [error, setError] = useState(null);
    if (error) throw error;

    const queryParameters = new URLSearchParams(window.location.search);
    const path = queryParameters.get("path");

    switch (path) {
        case 'impressum':
            contentsection = 'imprint';
            headline = TranslationService.translate('Impressum');
            break;
        case 'datenschutz':
            contentsection = 'privacy-terms';
            headline = TranslationService.translate('Datenschutz');
            break;
        case 'widerrufsbelehrung':
            contentsection = 'revocation';
            headline = TranslationService.translate('Widerrufsbelehrung');
            break;
        case 'agb':
            contentsection = 'terms-and-condition';
            headline = TranslationService.translate('AGB');
            break;
        case 'einloesebedingungen':
            contentsection = 'redeem-condition';
            headline = TranslationService.translate('Einlösebedingungen');
            break;
        default:
    }

    useEffect(() => {
        const API_URL = global.config.express.url + '/content';
        const API_DATA = {
            'shop-url': global.config.apidata.shopurl,
            'section': contentsection,
            'framepage': window.location.origin + window.location.pathname + '?mode=react',
            'language': global.config.language
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                setData(response);
            }
        }).catch((error) => { setError(error) });
    }, [contentsection, setData]);

    if (typeof (data) !== 'undefined') {

        if (data.link === null) {
            return <div className="container nopadding" dangerouslySetInnerHTML={{ __html: '<h1>' + headline + '</h1>' + data.content }} />
        }
        else {
            window.location.replace(data.link);
        }

    }


}

export default ContentPage;
